<template>
  <div class="login_box">
    <div class="circle_box">
      <img class="img_logo" :src="Logo" alt="" srcset="" />
    </div>
    <div class="login_text">欢迎来到智能英语系统</div>
    <a-form :form="form" @submit="handleSubmit">
      <a-form-item>
        <a-input
          allowClear
          class="input_phone"
          placeholder="请输入手机号"
          v-decorator="['phone', { rules: [{ required: true, message: '请输入手机号' }] }]"
        >
          <a-icon class="input_prefix" slot="prefix" type="mobile" />
        </a-input>
        <div>如果没有账号，请先到小程序中绑定手机号</div>
      </a-form-item>
      <a-form-item>
        <a-input-password
          allowClear
          class="input_password"
          placeholder="请输入密码"
          v-decorator="['password', { rules: [{ required: true, message: '请输入密码' }] }]"
        >
          <a-icon class="input_prefix" slot="prefix" type="lock" />
        </a-input-password>
      </a-form-item>
      <a-button :loading="submitting" type="primary" class="form_btn" html-type="submit">
        登录
      </a-button>
      <a-button v-if="showSgin" class="form_btn form_btn_sign" @click="signClick">
        注册
      </a-button>
    </a-form>
    <a-modal
      title="注册"
      :visible="signVisible"
      :confirm-loading="confirmLoading"
      @ok="handleSign"
      @cancel="handleCancel"
      okText="注册"
      cancelText="取消"
    >
       <a-form-model
        ref="ruleForm"
        :model="signForm"
        :rules="rules"
        :label-col="{span: 4}"
        :wrapper-col="{span: 20}"
      >
        <a-form-model-item label="姓名" prop="name">
          <a-input v-model="signForm.name"/>
        </a-form-model-item>
        <a-form-model-item label="手机号" prop="phone">
          <a-input v-model="signForm.phone"/>
        </a-form-model-item>
        <a-form-model-item label="密码" prop="password">
          <a-input v-model="signForm.password"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { userLogin,userSign } from '../../api/service';
import Logo from '../../assets/login/yyaxiu.png';
import {isPhone} from '@/utils/rules'
import { setToken, setHash, setUserInfo } from '../../utils/common';
export default {
  components: {},

  data() {
    var validateMobile = (rule, value, callback) => {
        if (!isPhone(value)) {
            return callback(new Error('手机号格式有误！'));
        } else {
            callback();
        }
    };
    return {
      Logo,
      submitting: false,
      formLayout: 'horizontal',
      form: this.$form.createForm(this, { name: 'login' }),
      signVisible: false,
      confirmLoading: false,
      showSgin: false,
      signForm: {
        name: '',
        phone: '',
        password: ''
      },
      rules: {
        name: [
          {required: true, message: '请输入姓名'},
        ],
        phone: [
          {required: true, message: '请输入手机号'},
          {validator: validateMobile}
        ],
        password: [
          {required: true, message: '请输入密码'},
          {min: 6, message: '密码最少6位',},
        ]
      }
    };
  },
  created () {
    if (this.$route.path.indexOf('yidianchuzhong') > -1) {
      this.showSgin = true
    }
  },
  methods: {
    handleSubmit(event) {
      event.preventDefault();
      this.form.validateFields((err, values) => {
        if (err) return;
        this.submitting = true;
        userLogin(values)
          .then(response => {
            const { token, token_type, hash_key: hashKey, user } = response.data;
            setHash(hashKey);
            setToken(token_type + ' ' + token);
            setUserInfo(JSON.stringify(user));
            this.submitting = false;
            this.$router.push('/book');
          })
          .catch(() => {
            this.submitting = false;
          });
        return;
      });
    },
    signClick () {
      this.signVisible = true
    },
    handleSign () {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.confirmLoading = true
          userSign(this.signForm).then(res => {
            this.signVisible = false
            this.confirmLoading = false
            this.$message.success('注册成功');
            this.signForm = {
              name: '',
              phone: '',
              password: ''
            }
          }).finally(() => {
            this.confirmLoading = false
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleCancel () {
      this.signVisible = false
      this.signForm = {
        name: '',
        phone: '',
        password: ''
      }
    }
  }
};
</script>

<style scoped lang="less">
@import '~ant-design-vue/lib/style/themes/default.less';
.login_box {
  width: 500px;
  min-height: 424px;
  padding: 80px 50px 50px 50px;
  background: white;
  top: calc(50% - 300px);
  left: calc(50% - 212px);
  box-shadow: 0 5px 20px 0 rgba(0, 83, 155, 0.2);
  border-radius: 20px;
  top: calc(50% - 300px);
  left: calc(50% - 212px);
  position: absolute;
  .login_text {
    font-size: 24px;
    color: #333333;
    text-align: center;
    margin-bottom: 30px;
  }
}
.circle_box {
  position: absolute;
  top: -57px;
  left: calc(50% - 57px);
  width: 114px;
  height: 114px;
  border-radius: 50%;
  background: white;
  .img_logo {
    width: 100px;
    position: relative;
    top: 30px;
    left: 5px;
  }
}

.input_phone,
.input_password {
  height: auto;
  font-size: 16px;
  color: #999999;
  text-align: left;
  .input_prefix {
    font-size: 20px;
  }
}

.ant-input-affix-wrapper .ant-input {
  padding-left: 50px;
}

.form_btn {
  width: 100%;
  height: 50px;
  border-radius: 20px;
  font-size: 22px;
  color: #ffffff;
}
.form_btn_sign {
  color: #444;
  margin-top: 20px;
}

.main {
  width: 500px;
  height: 424px;
  padding: 80px 50px 50px 50px;
  position: absolute;
  box-sizing: border-box;
  background: white;
  top: calc(50% - 300px);
  left: calc(50% - 212px);
  box-shadow: 0 5px 20px 0 rgba(0, 83, 155, 0.2);
  border-radius: 20px;
  @media screen and (max-width: @screen-sm) {
    width: 95%;
  }
  .icon {
    margin-left: 16px;
    color: rgba(0, 0, 0, 0.2);
    font-size: 24px;
    vertical-align: middle;
    cursor: pointer;
    transition: color 0.3s;
    &:hover {
      color: @primary-color;
    }
  }
  .other {
    margin-top: 24px;
    line-height: 22px;
    text-align: left;
    .register {
      float: right;
    }
  }
  .login {
    /deep/ .ant-tabs .ant-tabs-bar {
      margin-bottom: 24px;
      text-align: center;
      border-bottom: 0;
    }
    /deep/ .ant-form-item {
      margin: 0 2px 24px;
    }
    .getCaptcha {
      display: block;
      width: 100%;
    }
    .icon {
      margin-left: 16px;
      color: rgba(0, 0, 0, 0.2);
      font-size: 24px;
      vertical-align: middle;
      cursor: pointer;
      transition: color 0.3s;
      &:hover {
        color: @primary-color;
      }
    }
    .other {
      margin-top: 24px;
      line-height: 22px;
      text-align: left;
      .register {
        float: right;
      }
    }
    .prefixIcon {
      color: @disabled-color;
      font-size: @font-size-base;
    }
    .submit {
      width: 100%;
      margin-top: 24px;
    }
  }
}
</style>
